import React from "react";
import Seo from "../components/seo/seo";
import Layout from "../components/layout/layout";
import PageBanner from "../components/common/PageBanner";
import Clients from "../components/common/Clients";
import ProductsPage from "../components/products/ProductsPage";

const Animations = () => {
  return (
    <Layout>
      <Seo
        title='Products | VIRSAT - Virtual Reality Safety Training'
        description='VIRSAT - Virtual Reality Safety Training has developed a whole suit of products that helps companies to make significant steps in safety performance.'
        canonical='https://virsat.com/products'
      />
      <PageBanner title='Products' customClass='product' />
      <ProductsPage light />
      <Clients />
    </Layout>
  );
};

export default Animations;
