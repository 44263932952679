import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "gatsby";
import products from "../json/products";
import withLocation from "../utils/withLoation";
import { GlobalStateContext } from "../../context/GlobalContextProvider";

const ProductsPage = ({ search }) => {
  const { cat } = search;
  const [prodCat, setProdCat] = useState(cat ? cat : "All");
  const [prodList, setProdList] = useState(products);
  const state = useContext(GlobalStateContext);
  let container = useRef(null);

  useEffect(() => {
    if (!state.showPageLoading) {
      const cn = container.current;
      setTimeout(() => {
        cn.classList.remove("hide-container");
      }, state.containerAnimationDelay * 1000);
    }
  }, [state.showPageLoading, state.containerAnimationDelay]);

  function createMarkup(title) {
    return {
      __html: title,
    };
  }

  useEffect(() => {

    if (cat === "All" || !cat) {
      setProdList(products);
    } else {
      setProdList(products.filter((data) => data.category.includes(cat)));
    }
    setProdCat(cat ? cat : "All");
  }, [cat]);

  return (
    <section
      className={`main-container products light hide-container`}
      ref={container}
    >
      <div className='row top-pad bottom-pad fd-col'>
        <div className='column fd-col center'>
          <h2
            className='main-title light no-mar'
            data-aos={`fade-up`}
            data-aos-duration='1000'
          >
            Our Products
          </h2>
          <div
            className='title-temp second show-on-tablet-mobile center'
            data-aos={`fade-up`}
            data-aos-duration='1000'
          >
            {prodCat === "All" ? "All Products" : prodCat}
          </div>

          <nav
            className='product-links'
            data-aos={`fade-up`}
            data-aos-duration='1000'
          >
            <ul className='product-links-ul'>
              <li className='product-links-li'>
                <Link
                  className={`product-links-li-a ${
                    prodCat === "All" && "active"
                  }`}
                  to='/products/?cat=All'
                  //onClick={() => handleClick("All")}
                >
                  All Products
                </Link>
              </li>
              <li className='product-links-li'>
                <Link
                  className={`product-links-li-a ${
                    prodCat === "Animations" ? "active" : ""
                  }`}
                  to='/products/?cat=Animations'
                  //onClick={() => handleClick("Animations")}
                >
                  Animations
                </Link>
              </li>
              <li className='product-links-li'>
                <Link
                  className={`product-links-li-a ${
                    prodCat === "Apps" ? "active" : ""
                  }`}
                  //onClick={() => handleClick("Apps")}
                  to='/products/?cat=Apps'
                >
                  Apps
                </Link>
              </li>
              <li className='product-links-li'>
                <Link
                  className={`product-links-li-a ${
                    prodCat === "Culture Change" ? "active" : ""
                  }`}
                  //onClick={() => handleClick("Culture Change")}
                  to='/products/?cat=Culture Change'
                >
                  Culture Change
                </Link>
              </li>
              <li className='product-links-li'>
                <Link
                  className={`product-links-li-a ${
                    prodCat === "Games" ? "active" : ""
                  }`}
                  //onClick={() => handleClick("Games")}
                  to='/products/?cat=Games'
                >
                  Games
                </Link>
              </li>
              <li className='product-links-li'>
                <Link
                  className={`product-links-li-a ${
                    prodCat === "Learning from Incidents" ? "active" : ""
                  }`}
                  //onClick={() => handleClick("Learning from Incidents")}
                  to='/products/?cat=Learning from Incidents'
                >
                  Learning from Incidents
                </Link>
              </li>
              <li className='product-links-li'>
                <Link
                  className={`product-links-li-a ${
                    prodCat === "Life Saving Rules" ? "active" : ""
                  }`}
                  //onClick={() => handleClick("Life Saving Rules")}
                  to='/products/?cat=Life Saving Rules'
                >
                  Life Saving Rules
                </Link>
              </li>
              <li className='product-links-li'>
                <Link
                  className={`product-links-li-a ${
                    prodCat === "Training" ? "active" : ""
                  }`}
                  //onClick={() => handleClick("Training")}
                  to='/products/?cat=Training'
                >
                  Training
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div
          className={`column fd-col product-list ${prodCat
            .toLowerCase()
            .replace(/ /g, "-")}`}
        >
          {prodList.map((el) => (
            <div
              className='product-container'
              key={el.id}
              data-aos='flip-up'
              data-aos-duration='1000'
            >
              <div className='image-container'>
                <Link to={`/products/${el.id}`}>
                  <img src={el.img} alt={el.title} />
                </Link>
                <div className='learn-more'>Learn More</div>
              </div>
              <Link to={`/products/${el.id}`}>
                <h3
                  className='main-subtitle no-mar'
                  dangerouslySetInnerHTML={createMarkup(el.title)}
                />
              </Link>
              <p className='light no-mar'>{el.subtitle}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default withLocation(ProductsPage);
